@import "./colors.scss";

// btn-lateral
.btn-lateral-menu {
    width: 100%;
    background: transparent;
    border: none;
    font-size: 25px;
    margin: 7px 5px;
    padding: 5px 5px 0px 5px;
    color: #6c707e;
    cursor: pointer;
}

.btn-lateral-menu:hover {
    background: #dfe1e5;
    border-radius: 8px;
    color: #6c707e;
}

.btn-lateral-menu-active {
    background: $primary;
    border-radius: 8px;
    color: #fff;
    cursor: default;
}
// END btn-lateral


.btn-panel {
    border: 0px;
    background: $primary;
    color: white;
    font-size: 13px;
    margin: 7px 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    letter-spacing: 1px;
    cursor: pointer;
}

.btn-panel:hover {
    background: #dfe1e5;
    color: #6c707e;
}

.btn-panel:disabled {
    cursor: default;
    background: #dfe1e5;
    color: #6c707e;
}
