@import "./colors.scss";
@import "./fonts.scss";

@import "./navbar.scss";
@import "./content.scss";
@import "./table";
@import "./btn";
@import "./dnd-element";
@import "./editor-text.scss";
@import "./text.scss";
@import "./checkbox.scss";

#admin-content .MuiInputBase-input:focus {
    color: black !important;
}

.screen-max-admin {
    max-width: 1500px;
    padding-left: 30px;
    padding-right: 30px;
}
