body{
    margin: 0px !important;
    font-family: AdminFontMedium;
}
#admin-header{
    a{
        text-decoration: none;
        color: $font-dark;
    }
    .active {
        color: $font-primary;
        background-color: $primary;
        border-radius: 100px;
    }
    a:not(.active):hover{
        background-color: $background-grey-light;
        border-radius: 100px;
    }

    .first-menu, .second-menu{
        padding: 0px 10px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        align-items: center;
    }

    .first-menu{
        background-color: $background-grey-light;
        height: 44px;
        
        .MuiGrid-item{
            padding: 0px 8px;
        }

        .MuiGrid-item:last-child{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        #logo{
            max-height: 32px;
        }
    }

    .second-menu{
        height: 40px;

        a{
            padding: 3px 10px;
            font-size: 14px;
        }
        .MuiGrid-item{
            padding: 8px 0px;
        }
        .MuiGrid-item:first-child{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
        }
        .MuiGrid-item:last-child{
            text-align: end;
        }
    }

    .menu-account{
        display: flex;
        justify-content: flex-end;

        .MuiButtonBase-root{
            padding: 0px;
            .MuiListItemIcon-root{
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    
}

#fade-menu{
    .menu-name{
        padding: 6px 16px 10px;
        border-bottom: 1px solid $border-grey-light;
    }

    li{
        color: $font-secondary;
        font-size: 14px;
    }

    li:hover{
        color: inherit;
    }
}

a:active{
    color: inherit;
}

.MuiGrid-container{
    max-width: 100%;
    margin: auto;
}  

#admin-content{

    h1{
        margin-top: 0px;
        margin-bottom: 10px;
    }

    #admin-menu-left{
        h3{
            margin-top: 0px;
            font-size: 16px;
        }

        .menu-links{
            padding-bottom: 7px;

            a{
                font-family: AdminFontRegular;
                color: $font-dark;
                text-decoration: none;
                font-size: 14px;
                padding-top: 5px;
                padding-left: 12px;
                border-left: 1px solid $border-grey-light;
                padding-bottom: 7px;
            }

            .active {
                color: $font-secondary;
                border-left: 2px solid $border-secondary;
                font-family: AdminFontBold;
            }
        }
    }

    .MuiInputBase-input:focus{
        color: $primary;
    }
}

h1, h2, h3, h4, h5, h6{
    font-family: AdminFontBold;
}

h1{
    font-size: 28px;
}

.font-medium{
    font-family: AdminFontMedium !important;
}

.font-bold{
    font-family: AdminFontBold !important;
}

// Provisional
#admin-content > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.css-17wqeb1-MuiGrid-root > div:nth-child(1) > div > a{
    margin-top: 0px !important;
}

#prov-form{
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: AdminFontRegular;

    input, select, textarea{
        font-size: 14px;
        padding: 4px 8px;
        border-radius: 6px;
        border: 1px solid $border-grey-light;
        font-family: AdminFontRegular;
    }
    input::placeholder{
        font-size: 14px;
    }
    .mb-12{
        margin-bottom: 12px;
    }
    h3{
        font-size: 16px;
    }
    button{
        padding: 8px 22px !important;
        border: none;
        background-color: $primary;
        color: $font-light;
        font-family: AdminFontBold;
        border-radius: 6px !important;
        cursor: pointer;
    }
    p{
        font-family: AdminFontBold;
        color: $font-grey;
    }
}

.prov-form-select{
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid $border-grey-light;
    font-family: AdminFontMedium;
}

.prov-button-div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .prov-button{
        padding: 8px 22px !important;
        border: none;
        background-color: $primary;
        color: $font-light;
        font-family: AdminFontBold;
        border-radius: 6px !important;
        cursor: pointer;
    }
}
.MuiButton-containedPrimary{
    background-color: $primary !important;
}
