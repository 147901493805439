.kitchen-400{
    width: 50px;
}
.kitchen-800{
    width: 100px;
}
.kitchen-900{
    width: 113px;
}
.kitchen-600{
    width: 75px;
}
.kitchen-200{
    width: 25px;
}
.kitchen-100{
    width: 12.48px;
}
.kitchen-1000{
    width: 125px;
}
.kitchen-1100 {
    width: 137.5px;
}
.kitchen-1200{
    width: 150px;
}
.kitchen-1300{
    width: 162px;
}
.kitchen-1600{
    width: 200px;
}

/* INPUT Width - 18*/
.kitchen-input-400{
    width: 32px;
}
.kitchen-input-800{
    width: 82px;
}
.kitchen-input-900{
    width: 103px;
}
.kitchen-input-600{
    width: 65px;
}
.kitchen-input-200{
    width: 15px;
}
.kitchen-input-100{
    width: 2.3px;
}
.kitchen-input-1000{
    width: 107px;
    margin-top: 11px !important;
}
.kitchen-input-1100 {
    width: 117.5px;
}
.kitchen-input-1200{
    width: 140px;
}
.kitchen-input-1300{
    width: 152px;
}
.kitchen-input-1600{
    width: 182px;
}
