@import "./colors.scss";

// #####################
// ##### BOX RIGHT #####
// #####################
#box-right-menu-icons {
    background: $boxBackground;
    border-left: $border;
    height: 95vh;
    width: 40px;
    float: right;
    padding-right: 10px;
}

#box-right {
    background: $boxBackground;
    border-left: $border;
    width: 400px;

    .header {
        border-bottom: $border
    }

    .header-title {
        margin: 0px;
        padding: 15px;
    }

    .content {
        padding: 15px;
    }
}

// #####################
// #### BOX PRODUCT ####
// #####################
#box-product {
    border-top: $border;
    background: $boxBackground;
    bottom: 0;
    height: 260px;
    display: block;
    overflowX: auto;

    .header {
        border-bottom: $border
    }

    .header-title {
        margin: 0px;
        padding: 15px;
    }

    .content {
        padding: 15px;
    }
}

// #####################
// #### BOX ACTION #####
// #####################
#box-actions {
    margin-bottom: 10px;
    display: block;
    height: 50px;
}

.number-alerts-menu{
    position: absolute;
    font-size: 11px;
    //color: $primary;
    margin-left: -2px;
}

.number-alerts-menu-active{
    color: #fff;
}
