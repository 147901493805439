#root {
    overflow-x:hidden;
    overflow-y:hidden;
}

#logo{
    max-height: 32px;
}

@import "./colors.scss";
@import "./box.scss";
@import "./element-width.css";
@import "./btn.scss";
@import "./swiper.scss";
