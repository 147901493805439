@import "./colors.scss";

.icon-white path {
    stroke: white;
}

:focus-visible {
    outline: none;
}

// Scrollbar
.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 10px !important;
    border-radius: 0px !important;
}

.swiper-scrollbar-drag {
    border-radius: 0px !important;
    background-color: #c2c2c2 !important
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
}

::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
// End Scrollbar
