@import "./colors";

.box-default {
  border: 1px solid #dcdbdc !important;
  border-radius: 5px;
}

/* BUTTON PRIMARY */
.btn-primary-outline {
  padding: 5px 22px;
  border-radius: 6px;
  color: $primary;
  background-color: transparent;
  border: 1px solid $primary;
  box-shadow: none;
  font-size: 15px;
  font-family: AdminFontMedium;
  cursor: pointer;
}

.btn-primary {
  padding: 5px 22px;
  border-radius: 6px;
  color: $font-primary;
  background-color: $primary;
  border: 1px solid $primary;
  box-shadow: none;
  font-size: 15px;
  font-family: AdminFontMedium;
  cursor: pointer;
}

.btn-primary:disabled, .btn-primary-outline:disabled {
  background-color: #e7bcc0;
  color: $primary;
  border-color: #e7bcc0;
  cursor: default;
}

.MuiButton-containedPrimary {
  padding: 5px 22px !important;
  border-radius: 6px !important;
  color: $font-primary !important;
  background-color: $primary !important;
  border: 1px solid $primary !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: AdminFontMedium !important;
}

.MuiButton-containedPrimary:disabled {
  background-color: #e7bcc0 !important;
  color: $primary !important;
  border-color: #e7bcc0 !important;
}

/* BUTTON SECONDARY */
.MuiButton-containedSecondary {
  padding: 5px 22px !important;
  border-radius: 6px !important;
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #d8d8d8 !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: AdminFontMedium !important;
}

.MuiButton-textSecondary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: transparent !important;
}

/* checked */
.MuiSwitch-colorPrimary.Mui-checked {
  color: $primary !important;
}

.MuiSwitch-track {
  background: $primary !important;
}

/* Link */
.MuiTypography-colorPrimary {
  color: $primary !important;
}

/* Menu */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: $primaryOpacity !important;
}

.MuiListItem-root.MuiListItem-gutters:active {
  color: #000 !important;
}

.p-20 {
  padding: 20px;
}

.p-0 {
  padding: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mr-5px {
  margin-right: 5px;
}

.mt-2 {
  margin-top: 20px !important;
}

.ml-2 {
  margin-left: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.w-100 {
  width: 100% !important;
}

.MuiTableCell-head {
  font-size: 12px !important;
}

.alert-success {
  background-color: $success;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #000;
}

.alert-danger {
  background-color: $danger;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #000;
}

a.MuiButtonBase-root:hover {
  color: #000 !important;
}

/* DASH background */
.css-fxbtpg{
  background-color: #f5f4f9 !important;
}
