.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    opacity: 0.2;
}

.table-title {
    font-size: 13px;
    font-family: AdminFontRegular;
    text-transform: uppercase;
}

.pagination {
    color: $font-dark;
    width: 100%;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 14px;
    font-family: AdminFontRegular;

    p {
        margin: 0px;
    }

    div {
        display: flex;
        align-items: center;
    }

    .pagination-buttons {
        button {
            font-family: AdminFontMedium;
            border: 1px solid $border-grey;
            background-color: $background-light;
            border-radius: 6px;
            padding: 6px 10px;
            cursor: pointer;
            letter-spacing: 1px;
            color: $font-dark;
        }

        button:disabled {
            color: $font-grey;
            border: 1px solid $border-grey;
            background-color: $background-light;
            cursor: default;
        }
    }
}
