$primary: #ea0c1f;
$primaryOpacity: rgba(234, 12, 31, 0.2);

$font-primary: #fff;
$font-secondary: #ea0c1f;
$font-light: #fff;
$font-grey: #c9c9c9;
$font-dark: #1A1B25;

$border-secondary: #3e1952;
$border-light: #fff;
$border-grey-light: #eee;
$border-grey: #c9c9c9;
$boder-grey-dark: #444444;

$background-secondary: #3e1952;
$background-light: #fff;
$background-grey-light: #f4f6f8;
$background-grey: #a7a7a7;
$background-grey-dark: #363636;
$background-dark: #151515;
$background-dark-absolute: #000;

$success: #3edb93;
$danger: #ff5a5f;

:root {
    --primary-color: #ea0c1f;
}
