@font-face {
    font-family: AdminFontRegular;
    src: url('../../assets/fonts/Raleway-Regular.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontItalic;
    src: url('../../assets/fonts/Raleway-Italic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontLight;
    src: url('../../assets/fonts/Raleway-Light.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontLightItalic;
    src: url('../../assets/fonts/Raleway-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraLight;
    src: url('../../assets/fonts/Raleway-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraLightItalic;
    src: url('../../assets/fonts/Raleway-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontThin;
    src: url('../../assets/fonts/Raleway-Thin.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontThinItalic;
    src: url('../../assets/fonts/Raleway-ThinItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontMedium;
    src: url('../../assets/fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontMediumItalic;
    src: url('../../assets/fonts/Raleway-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontBold;
    src: url('../../assets/fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontBoldItalic;
    src: url('../../assets/fonts/Raleway-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraBold;
    src: url('../../assets/fonts/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontExtraBoldItalic;
    src: url('../../assets/fonts/Raleway-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontBlack;
    src: url('../../assets/fonts/Raleway-Black.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontBlackItalic;
    src: url('../../assets/fonts/Raleway-BlackItalic.ttf') format('truetype');
}