.swiper-button-prev, .swiper-button-next {
    background: rgba(255, 255, 255, .3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: 1.5px solid rgba(209, 213, 219, 0.3);
    padding: 10px;
    height: 162px !important;
    top: 0px !important;
    color: #ea0c1f !important;
}

.swiper-button-prev {
    left: 0px !important;
    transition: transform 0.3s ease-in-out;
}

.swiper-button-next {
    right: 0px !important;
    transition: transform 0.3s ease-in-out;
}

.swiper-button-disabled {
    display: none !important;
}

.swiper-button-prev:hover {
    transform: translateX(-5px);
}

.swiper-button-next:hover {
    transform: translateX(5px);
}
